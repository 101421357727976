import { render, staticRenderFns } from "./dpIndex.vue?vue&type=template&id=53099a2c&scoped=true"
import script from "./dpIndex.vue?vue&type=script&lang=js"
export * from "./dpIndex.vue?vue&type=script&lang=js"
import style0 from "./dpIndex.vue?vue&type=style&index=0&id=53099a2c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53099a2c",
  null
  
)

export default component.exports